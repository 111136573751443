@import url('https://fonts.googleapis.com/css2?family=Comforter&display=swap');

* {
  box-sizing: border-box;
  font-family: "Roboto Serif", serif;
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
}

:root {
    --text-color: #1C1C1C;
    --background-color: #f2f2f2;
    --grey-color: #d9d9d9;
    --accent-color: #4e7864;
}

a {
  text-decoration: none;
}

form,
input,
textarea,
.about, 
.publications, 
.media,
.media-card,
.media-heading
.about-heading,
.publications-heading,
.buy-btn,
.contact-heading
 {
  color: var(--text-color);
}

h2 {
  font-weight: 500;
}

header {
  position: relative;
  height: 580px;
  background-color: var(--accent-color);
}

.success-message, .error-message {
  margin-left: 1.3em;
}


.banner {
  position: relative; 
  background-color: var(--accent-color);
  width: 100%; 
  height: 500px; 
  opacity: 0.2;
  /* background-image: url('./Assets/Banner-mobile.png');  */
  background-size: cover; 
}

.andrew-thomas-text {
  position: absolute;
  top: 0;
  background: none;
  color: var(--background-color);
  font-size: 1.2em;
  padding: 1.85em 1em 1em 2.5em;
  z-index: 10;
}

.intro-text {
  position: relative;
  display: flex;
  font-size: 1.6em;
  color: var(--background-color);
  top: 0;
  background: none;
  justify-content: center;
  padding: 0em 1em 0em 1em;
  margin-top: -16em;
  margin-bottom: 2em;
  z-index: 100;
  opacity: 1;
  
}

.headshot {
  position: relative;
  background: none;
  display: block;
  margin: auto;
  margin-bottom: 2em;
  margin-top: -2em;
  z-index: 10;
  width: 250px;
  border-radius: 500px;
}

/* ABOUT */

.about, .publications, .media{
  padding: 1em 2em 0em 2em;
  color: var(--text-color);
}

.about-heading {
  margin: 1em 0em 1em 0em;
}

/* PUBLICATIONS*/

.publications-heading {
  margin: 1em 0em 1em 0em;
}

.media-heading {
  margin: 2em 0em 0em 0em;
}

.buy-container {
  margin-top: 1em;
}

.buy-btn {
  background-color: var(--grey-color);
  padding: 1em;
  border-radius: 4px;
  margin-top: 10em;
  color: var(--text-color);
  font-weight: 600;
}

.buy-btn:hover {
  color: var(--background-color);
  background-color: var(--accent-color);
}

/* CONTACT FORM */

.contact-form-card {
  margin-top: 6em;
}

form {
  display: flex;
  flex-direction: column;
  padding: 2em;
  border-radius: 4px;
  box-shadow: none;
}

input, textarea {
  padding: 1em;
  border: none;
  outline: none;
  border-radius: 4px;
  background-color: var(--grey-color);
  border: 2px solid transparent;
  font-size: 1em;
}

input:hover, textarea:hover {
  border: 2px solid var(--text-color);
}

.contact-heading {
  margin: 0em 0em -1em 1.3em;
  
}

label {
  margin-bottom: 1em;
  margin-top: 1em;
}

textarea {
  height: 200px;
  width: 100%;
  resize: vertical;
  margin-bottom: 1em;
  
}

.submit-btn {
  display: flex;
  width: auto;
  background-color: var(--grey-color);
  border: none;
  cursor: pointer;
  color: var(--text-color);
  box-shadow: none;
  justify-content: center;
  text-align: center;
  margin-top: 1em;
  margin-bottom: 6em;
  font-weight: 600;
}

.submit-btn:hover {
  border: none;
  background-color: var(--accent-color);
  color: var(--background-color);
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px var(--grey-color) inset;
  -webkit-text-fill-color: var(--text-color);
}

/* Articles */

.articles {
  text-decoration: underline;
  color: var(--text-color);
  
}

.articlesimg {
  opacity: 0.7;
  width: 100%;
  border-radius: 8px;
}

.articlesimg:hover {
  opacity: 1;
  transition: .5s ease
}

iframe {
  width: 100%;
  height: calc(0.5625 * 100vw - 44px);
  border-radius: 8px;
}

/* ICONS */

.twitter-icon {
  position: absolute;
  fill: var( --background-color);
  width: 17px; 
  background: none;
  z-index: 100;
  margin: 2.4em 0em 2em 1.55em;
}

.about-icon, .book-icon, .media-icon, .camera-icon{
  fill: var(--grey-color);
  display: flex;
  margin-right: 6em;
  margin-left: -11em; 
}

.about-icon{
  margin-bottom: -9.5em;
  width: 150px; 
}

.media-icon{
  padding-top: 3em;
  margin-bottom: -10.5em;
  width: 120px; 
}

.book-icon {
  margin-top: 3em;
  margin-bottom: -11em;
  width: 120px;
}

.camera-icon {
  margin-top: 0em;
  margin-bottom: -5.5em;
  width: 120px;
}

.contact-icon  {
  fill: var(--grey-color);
  display: flex;
  margin-right: 6em;
  margin-left: -9.5em; 
  margin-top: 5em;
  margin-bottom: -12.5em;
  width: 120px;  
}

.media-card-container {
  margin-bottom: 2em;
  background-color: #d9d9d9;
  border-radius: 8px;
  
}

.media-card {
  background-color: #d9d9d9;
  border-radius: 8px;
}

.articlesimgs {
  width: 100%;
  margin: 1em 0em -0.3em 0em;
}

.article-heading {
  padding: 1em 2em 0em 1em;
  font-weight: 500;
  background: none;
}

.article-author {
  padding: 0.5em 2em 0em 1.2em;
  background: none;
  font-size: 1em;
}

.article-headshot {
  width: 50px;
  background: none;
  margin: 1em;
}

em {background: none;}

.headshot-name {
  display: flex;
  margin-top: -4.4em;
  margin-left: 5em;
  background: none;
  color: var(--text-color);
}

.headshot-title {
  display: flex;
  margin-top: -1em;
  margin-left: 5em;
  padding-bottom: 1em;
  padding-right: 2em;
  background: none;
}

.articlesimgs:hover {
  opacity: 0.7;
  transition: ease-in 100ms;
}

@media (max-width: 1099px) {
.contact-icon, .about-icon, .book-icon, .media-icon, .camera-icon {
  display: none;
  }
}

@media (min-width: 376px) {
  header {
    height: 550px;
  }
}

@media (min-width: 462px) {
  header {
    height: 500px;
  }
}

@media (min-width: 1200px) {

  .contact-form-card {
    display: flex;
    justify-content: center;
  }
  form, .about, .publications, .contact, .media {
    width: 700px;
  }

  .about, .publications, .contact, .media {
    margin: auto;
    font-size: 1.2em;
  }

  .headshot {
    right: 30em;
    top: 3.8em;
    width: 300px;
  }

  .intro-text {
    margin: auto;
    width: 22em;
    left: 1.5em;
    font-size: 2em;
    top: 2.3em;
  }

  .publications-heading {
    margin: 3em 0em 1em 0em;
  }

  .media-heading {
    margin: 3em 0em 1em 0em;
  }

  .about {
    margin-top: 3em; 
  }

  .intro-text, .headshot {
    margin-top: -12em;
  }

  .media-card {
    width: 623px;
    margin: auto;
    
  }

  .article-heading {
    width: 27em;
  }

  .article-headshot {
    margin-bottom: 1.5em;
  }

  iframe {
    height: 349px;
  }
}

 








